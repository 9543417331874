<div class="rounded-md bg-white shadow-lg flex flex-col overflow-hidden" @fade>
  <div class="bg-white w-full min-h-[150px] relative">
    <img [routerLink]="['/productos/' + product._id]"  [queryParams]="{cid: cid, sort: sort, page: page}" *ngIf="product.image_url[0]" data-src="{{
      product.image_url[0]
        .replace(
          'https://firebasestorage.googleapis.com/v0/b/wink-309217.appspot.com/',
          'https://ik.imagekit.io/d3ddbxom7/'
        )
        .concat('&tr=w-600,h-600')
    }}" [alt]="product.name" data-sizes="auto" [id]="product._id" [ngClass]="{ lazyload: true, show: image.complete }"
      #image class="w-full h-auto object-contain object-center lazyload rounded-t-md cursor-pointer" />
    <div *ngIf="product.show_in_store && !product.active"
     class="w-2/6 absolute top-0 left-0 bg-secondary text-black font-bold text-center py-2 cursor-not-allowed ">
      Agotado
    </div>
  </div>
  <div class="flex flex-col p-2 gap-2">
    <div class="flex flex-col gap-2">
      <span class="text-gray-500 text-sm  truncate">{{ product.name }}</span>
      <span class="text-black text-sm   font-bold">{{ product.price | currency:
        "USD":"symbol":undefined:"en-US" }}</span>
    </div>
    <div class="flex w-full justify-center items-center  ">
      <button *ngIf="!showCounter" (click)="addProduct(product)" [disabled]="product.show_in_store && !product.active"
        class="rounded-md bg-primary text-white font-bold text-sm w-full py-2 px-4 border border-primary disabled:bg-slate-400 disabled:border-slate-400 disabled:opacity-40 disabled:cursor-not-allowed disabled:text-slate-900">
        {{ product.show_in_store && !product.active ? 'Agotado' : 'Agregar' }}
      </button>
      <div *ngIf="showCounter"
        class="flex items-center rounded-md bg-white text-primary font-bold text-sm w-full py-2 px-4 border border-primary">
        <button class="bg-transparent text-primary " (click)="remove(product)">
          <i class="fas fa-minus"></i>
        </button>
        <span class="text-sm w-full text-center text-black font-bold">{{count}}</span>
        <button class="bg-transparent text-primary " (click)="add(product)">
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
  </div>

</div>